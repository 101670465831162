<style lang="scss" scoped>
.goods {
	padding: 10px;
	box-sizing: border-box;
}
.banner {
	width: 100%;
}
.route_icon {
	font-size: 32px;
}
.ybx_bill {
	color: $success;
}
.ybx_telephone {
	color: $color_main;
	font-size: 26px;
}
.goods_list {
	li {
		margin-top: 20px;
		border-radius: 12px;
		box-shadow: 0 2px 12px #ccc;
		font-size: 12px;
		overflow: hidden;
		.info_box {
			padding: 10px;
			position: relative;
			color: #fff;
		}
		.goods_name {
			padding: 5px;
			font-size: 0.3rem;
			font-weight: bold;
		}
		.goods_info_list {
			margin-top: 0.2rem;
			margin-bottom: 0.4rem;
			.icon {
				margin: 0 4px 0 10px;
			}
			span {
				font-size: 0.16rem;
				line-height: 0.36rem;
				color: #d0e5f1;
			}
		}
		.goods_info {
			background-image: url(../../assets/image/sundry/line.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% auto;
		}
		.goods_info_row {
			display: flex;
			align-items: center;
			padding: 0.14rem;
			justify-content: space-between;
		}
		.b_text {
			color: $color_active_3;
			text-align: center;
			padding: 12px 0;
			font-size: 0.3rem;
			font-weight: bold;
		}
	}
}
.goods_list {
	.box {
		background-color: #fff;
		border-radius: 8px;
	}
	.info_header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 0.2rem;
		.tag {
			color: $color_active_3;
			font-size: 0.3rem;
		}
		.company {
			color: $font_color_sec;
			font-size: 0.18rem;
		}
	}
	.info_box {
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}
}
</style>

<template>
	<div class="goods">
		<header-nav :hasBack="false" :hasShare="true" :hasLogout="!Boolean(userDesc)">产品列表</header-nav>
		<img class="banner" src="@/assets/image/sundry/goods_banner.png" alt="banner" />
		<van-grid clickable :column-num="2">
			<van-grid-item v-if="!Boolean(userDesc)" text="订单" :to="`bill?pid=${pid}`">
				<span slot="icon" class="ybx_bill iconfont route_icon"></span>
			</van-grid-item>
			<van-grid-item v-else text="保单查询" :to="`/pfileSearch`">
				<span slot="icon" class="ybx_bill iconfont route_icon"></span>
			</van-grid-item>
			<van-grid-item icon="search" :text="waitRepeatText">
				<span slot="icon" class="ybx_telephone iconfont route_icon"></span>
			</van-grid-item>
		</van-grid>
		<ul class="goods_list">
			<li v-for="(item, index) in goodsList" :key="index" @click="toAccident(item)">
				<div class="box">
					<div class="info_header">
						<span class="tag" v-text="item.labelName"></span>
						<span class="company" v-text="item.insurerName === '北京人保财险' ? '人保财险' : item.insurerName"></span>
					</div>
					<div class="info_box" :style="{ 'background-color': item.proDescribeDict.color }">
						<div class="goods_name" v-text="item.proName">装修工意外险</div>
						<div class="goods_info_list">
							<div v-for="(t, ti) in item.proDescribeDict.des" :key="`${index}_${ti}`">
								<span class="icon ybx_duihao iconfont"></span>
								<span v-text="t"></span>
							</div>
						</div>
						<div class="goods_info">
							<div class="goods_info_row border">
								<div class="info_label">总保额</div>
								<div class="info_label">保费</div>
								<div class="info_label">保障期限</div>
							</div>
							<div class="goods_info_row">
								<div class="info_val" v-text="item.proDescribeDict.sumInsured">最高500万</div>
								<div class="info_val" v-text="item.proDescribeDict.price">￥ 10.2起</div>
								<div class="info_val" v-text="item.proDescribeDict.protectLimit">1天</div>
							</div>
						</div>
					</div>
					<div class="b_text">获取保障</div>
				</div>
			</li>
		</ul>
		<page-footer></page-footer>
	</div>
</template>

<script>
import { Grid, GridItem } from 'vant';
import { http_goods } from '@/request/sundry';
import { http_getDict } from '@/request/common';
import headerNav from './components/headerNav.vue';

export default {
	name: 'goods', // 商品页面
	components: {
		[Grid.name]: Grid,
		[GridItem.name]: GridItem,
		headerNav,
	},
	data() {
		return {
			pid: '',
			userDesc: '',
			goodsList: [],
			waitRepeatText: '敬请期待',
		};
	},
	created() {
		this.pid = this.$route.query.pid;
		this.userDesc = this.$route.query.user;
		this.getRepeatText();
		this.getGoods();
	},
	methods: {
		toAccident(product) {
			// if(product.proName == '华泰团体意外险') {
			if (product.proName == '“耀安心”团体人身意外伤害保障' || product.proName == '华泰团体意外险') {
				this.$router.push({
					path: 'product',
					query: {
						productId: product.proId,
						companyId: product.companyId,
						pid: this.pid,
						user: this.userDesc,
					},
				});
			} else {
				this.$router.push({
					path: 'product',
					query: {
						productId: product.proId,
						companyId: product.insurerId,
						pid: this.pid,
						user: this.userDesc,
					},
				});
			}
		},

		// 去登录页
		toLoginPage(err) {
			if (err.message === '用户未登录') {
				this.$router.push({
					path: 'login',
					query: {
						pid: this.pid,
						user: this.userDesc,
					},
				});
			}
		},

		// 获取商品列表
		getGoods() {
			http_goods()
				.then(res => {
					let list = res
						.filter(item => item.isReserver)
						.map(g => {
							let proDescribeDict = {};
							if (g.proDescribe) {
								let a = g.proDescribe.split('&');
								proDescribeDict.color = a[0];
								proDescribeDict.sumInsured = a[1];
								proDescribeDict.price = a[2];
								proDescribeDict.protectLimit = a[3];
								proDescribeDict.des = a.slice(4);
							}
							g.proDescribeDict = proDescribeDict;
							return g;
						});
					this.goodsList = list.sort((x, y) => {
						return x.sortCol - y.sortCol;
					});
					console.log(this.goodsList);
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},

		// 保障期限格式化
		viewTimeFormatter(text) {
			if (text) {
				return text.split('（')[0];
			} else {
				return '';
			}
		},

		// 获取字典数据
		getRepeatText() {
			http_getDict('niuMoGoodsWaitRepeat')
				.then(res => {
					let a = res.filter(item => item.code === `nmgwr-${this.pid}`);
					if (a.length) {
						this.waitRepeatText = a[0].value;
					}
				})
				.catch(err => {
					this.toLoginPage(err);
				});
		},
	},
};
</script>
